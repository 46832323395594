import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';


const json = {
  1: { // Exercise num
    unit: 'Culture',
    id: 'WB1-C-P50-E1',
    //audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page50/E1/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '0% 50%',
    titleImage: 'img/FriendsPlus/Page50/E1/1.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page50/E1/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page50/E1/5.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page50/E1/8.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page50/E1/11.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page50/E1/14.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page50/E1/17.jpg', isMatching: true, id: 6 },
      ],
      [
        { url: 'img/FriendsPlus/Page50/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page50/E1/4.jpg', isMatching: true, id: 11 },
        { url: 'img/FriendsPlus/Page50/E1/7.jpg', isMatching: true, id: 12 },
        { url: 'img/FriendsPlus/Page50/E1/10.jpg', isMatching: true, id: 13 },
        { url: 'img/FriendsPlus/Page50/E1/13.jpg', isMatching: true, id: 14 },
        { url: 'img/FriendsPlus/Page50/E1/16.jpg', isMatching: true, id: 15 },
        { url: 'img/FriendsPlus/Page50/E1/19.jpg', isMatching: true, id: 16 },
      ],
    ],
    answers: ['1-12', '2-15', '3-14', '4-16', '5-11', '6-13'],
  },


}

export default json;