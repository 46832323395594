import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';

const json = {
    1: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P45-E1',
        audio: '',
        video: '',
        component: D1,
        typeInput: 'center',
        fontSize: 40,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page45/E1/Key/answerKey.png',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page45/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page45/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/3.jpg', input: true, answer: "6" },
                { url: 'img/FriendsPlus/Page45/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page45/E1/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page45/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/7.jpg', input: true, answer: "8" },
                { url: 'img/FriendsPlus/Page45/E1/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page45/E1/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page45/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/11.jpg', input: true, answer: "7" },
                { url: 'img/FriendsPlus/Page45/E1/12.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/13.jpg', input: true, answer: "9" },
                { url: 'img/FriendsPlus/Page45/E1/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page45/E1/15.jpg' },
            ],

        ],
    },
    2: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P45-E2',
        // audio: 'img/FriendsPlus/Page45/E2/Audio/sing.e2.p11.mp3',
        video: '',
        component: LT2,
        exerciseKey: 'img/FriendsPlus/Page45/E2/Key/answerKey.png',
        recorder: true,
        toAnchor: '50% -1%',
        fromAnchor: '50% 100%',
        // titleImage: 'img/FriendsPlus/Page45/E2/1.jpg',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page45/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page45/E2/2.jpg', isMatching: true, id: 1 },
                { url: 'img/FriendsPlus/Page45/E2/3.jpg', isMatching: true, id: 2 },
                { url: 'img/FriendsPlus/Page45/E2/4.jpg', isMatching: true, id: 3 },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page45/E2/5.jpg' },
                { url: 'img/FriendsPlus/Page45/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page45/E2/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page45/E2/8.jpg', isMatching: true, id: 4 },
                { url: 'img/FriendsPlus/Page45/E2/9.jpg', isMatching: true, id: 5 },
                { url: 'img/FriendsPlus/Page45/E2/10.jpg', isMatching: true, id: 6 },
            ],

        ],
        answers: ['1-6', '2-4', '3-5'],
    },
    3: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P45-E3',
        audio: '',
        video: '',
        component: D1,
        // recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page45/E3/1.jpg' },
            ],

        ],
    },



}

export default json;