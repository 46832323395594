import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';


const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB1-U2-P21-E1',
    //audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page21/E1/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '0% 50%',
    titleImage: 'img/FriendsPlus/Page21/E1/1.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page21/E1/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page21/E1/5.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page21/E1/8.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page21/E1/11.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page21/E1/14.jpg', isMatching: true, id: 5 },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E1/4.jpg', isMatching: true, id: 6 },
        { url: 'img/FriendsPlus/Page21/E1/7.jpg', isMatching: true, id: 7 },
        { url: 'img/FriendsPlus/Page21/E1/10.jpg', isMatching: true, id: 8 },
        { url: 'img/FriendsPlus/Page21/E1/13.jpg', isMatching: true, id: 9 },
        { url: 'img/FriendsPlus/Page21/E1/16.jpg', isMatching: true, id: 10 },
      ],
    ],
    answers: ['1-8', '2-9', '3-6', '4-10', '5-7'],
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB1-U2-P21-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page21/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page21/E2/2_1.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/2_2.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/2_3.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/2_4.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/2_5.jpg' },
      ],
    ]
  },


}

export default json;