import UI from '../../components/ExcerciseTypes/UseIt';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P26-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page26/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P26-E2',
    audio: '',
    video: '',
    component: D1,
    //recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page26/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page26/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page26/Audio/hat.mp3' },
        { url: 'img/FriendsPlus/Page26/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page26/Audio/horse.mp3' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page26/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page26/Audio/insect.mp3' },
        { url: 'img/FriendsPlus/Page26/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page26/Audio/ill.mp3' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P26-E3',
    audio: '/img/FriendsPlus/Page26/Audio/audio.e3.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: 'img/FriendsPlus/Page26/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page26/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page26/E3/2.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E3/3.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E3/4.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page26/E3/5.jpg', input: 4 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page26/E3/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page26/E3/7.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page26/E3/8.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E3/9.jpg', input: 4, isCorrect: true },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },







}

export default json;