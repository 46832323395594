import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB1-U4-P31-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E1/1.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    audio: '',
    video: '',
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page31/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page31/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page31/E2/4.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page31/E2/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page31/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page31/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page31/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/9.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page31/E2/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page31/E2/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page31/E2/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page31/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page31/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page31/E2/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page31/E2/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page31/E2/18.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },



}

export default json;