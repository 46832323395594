import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Culture 6',
    id: 'WB1-C-P57-E1',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    fontSize: 40,
    inputHeight: 42,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page57/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page57/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page57/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page57/E1/3.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page57/E1/4.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page57/E1/5.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page57/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page57/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page57/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page57/E1/9.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page57/E1/10.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page57/E1/11.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page57/E1/12.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page57/E1/13.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page57/E1/14.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page57/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page57/E1/16.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page57/E1/17.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page57/E1/18.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page57/E1/19.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page57/E1/20.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page57/E1/21.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page57/E1/22.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Culture 6',
    id: 'WB1-C-P57-E2',
    audio: '',
    video: '',
    component: D1,
    //typeInput: 'center',
    fontSize: 30,
    inputHeight: 32,
    isAllowSubmit: true,
    hideBtnFooter : true,
    //exerciseKey: 'img/FriendsPlus/Page57/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page57/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page57/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page57/E2/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page57/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page57/E2/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page57/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page57/E2/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page57/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page57/E2/12.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/13.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/14.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page57/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page57/E2/16.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page57/E2/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/19.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/20.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page57/E2/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page57/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page57/E2/23.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page57/E2/24.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page57/E2/25.jpg' },
      ],

    ],
  },


}
export default json;