
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import UnderLine_Typein from '../../components/ExcerciseTypes/Design/UnderLine_Typein';

const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P37-E1',
    audio: '',
    video: '',
    component: UnderLine_Typein,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page37/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page37/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/6.jpg', isInput: true, answer: "2" },
        { url: 'img/FriendsPlus/Page37/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page37/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/10.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page37/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/14.jpg', isInput: true, answer: '7' },
        { url: 'img/FriendsPlus/Page37/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page37/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/18.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page37/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/20.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P37-E2',
    audio: '',
    video: '',
    component: D1,
    // recorder: true,
    // isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E2/1.jpg' },
      ],

    ],
  },

}

export default json;