import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P29-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: true,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page29/E3/1.jpg' },
      ],
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P29-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page29/E4/1.jpg' },
      ],
    ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P29-E5',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 1,
    exerciseKey: '',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E5/2.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E5/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E5/4.jpg', input: 1, isCorrect: true },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page29/E5/5.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },



}

export default json;