import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB1-U2-P16-E1',
    //audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page16/E1/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '0% 50%',
    titleImage: 'img/FriendsPlus/Page16/E1/1.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page16/E1/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page16/E1/5.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page16/E1/8.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page16/E1/11.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page16/E1/14.jpg', isMatching: true, id: 5 },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page16/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page16/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page16/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page16/E1/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E1/4.jpg', isMatching: true, id: 6 },
        { url: 'img/FriendsPlus/Page16/E1/7.jpg', isMatching: true, id: 7 },
        { url: 'img/FriendsPlus/Page16/E1/10.jpg', isMatching: true, id: 8 },
        { url: 'img/FriendsPlus/Page16/E1/13.jpg', isMatching: true, id: 9 },
        { url: 'img/FriendsPlus/Page16/E1/16.jpg', isMatching: true, id: 10 },
      ],
    ],
    answers: ['1-9', '2-8', '3-6', '4-10', '5-7'],
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB1-U2-P16-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page16/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page16/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page16/Audio/desk.mp3' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page16/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page16/Audio/chair.mp3' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page16/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page16/Audio/crayon.mp3' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page16/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page16/Audio/pencil.mp3' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page16/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page16/Audio/notebook.mp3' },
      ],


    ],
  },

}

export default json;