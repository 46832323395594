import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';




const json = {
    1: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P44-E1',
        // audio: 'img/FriendsPlus/Page44/E1/Audio/sing.e2.p11.mp3',
        video: '',
        component: LT2,
        exerciseKey: 'img/FriendsPlus/Page44/E1/Key/answerKey.png',
        recorder: true,
        toAnchor: '50% -1%',
        fromAnchor: '50% 100%',
        // titleImage: 'img/FriendsPlus/Page44/E1/1.jpg',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page44/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page44/E1/2.jpg', isMatching: true, id: 1 },
                { url: 'img/FriendsPlus/Page44/E1/3.jpg', isMatching: true, id: 2 },
                { url: 'img/FriendsPlus/Page44/E1/4.jpg', isMatching: true, id: 3 },
                { url: 'img/FriendsPlus/Page44/E1/5.jpg', isMatching: true, id: 4 },
                { url: 'img/FriendsPlus/Page44/E1/6.jpg', isMatching: true, id: 5 },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page44/E1/7.jpg' },
                { url: 'img/FriendsPlus/Page44/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page44/E1/9.jpg' },
                { url: 'img/FriendsPlus/Page44/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page44/E1/11.jpg' },

            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page44/E1/12.jpg', isMatching: true, id: 6 },
                { url: 'img/FriendsPlus/Page44/E1/13.jpg', isMatching: true, id: 7 },
                { url: 'img/FriendsPlus/Page44/E1/14.jpg', isMatching: true, id: 8 },
                { url: 'img/FriendsPlus/Page44/E1/15.jpg', isMatching: true, id: 9 },
                { url: 'img/FriendsPlus/Page44/E1/16.jpg', isMatching: true, id: 10 },
            ],

        ],
        answers: ['1-7', '2-9', '3-10', '4-8', '5-6'],
    },

    2: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P44-E2',
        audio: '',
        video: '',
        component: D1,
        typeInput: 'center',
        fontSize: 30,
        inputHeight: 33,
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page44/E2/Key/answerKey.png',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page44/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page44/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page44/E2/3.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page44/E2/4.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page44/E2/5.jpg', input: true, answer: "k" },
                { url: 'img/FriendsPlus/Page44/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page44/E2/7.jpg', input: true, answer: "k" },
                { url: 'img/FriendsPlus/Page44/E2/8.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page44/E2/9.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page44/E2/10.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page44/E2/11.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page44/E2/12.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page44/E2/13.jpg' },
                { url: 'img/FriendsPlus/Page44/E2/14.jpg', input: true, answer: "m" },
                { url: 'img/FriendsPlus/Page44/E2/15.jpg', input: true, answer: "o" },
                { url: 'img/FriendsPlus/Page44/E2/16.jpg', input: true, answer: "m" },
                { url: 'img/FriendsPlus/Page44/E2/17.jpg' },
                { url: 'img/FriendsPlus/Page44/E2/18.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page44/E2/19.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page44/E2/20.jpg', input: true, answer: "o" },
                { url: 'img/FriendsPlus/Page44/E2/21.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page44/E2/22.jpg' },
                { url: 'img/FriendsPlus/Page44/E2/23.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page44/E2/24.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page44/E2/25.jpg', input: true, answer: "k" },
                { url: 'img/FriendsPlus/Page44/E2/26.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page44/E2/27.jpg' },
            ],

        ],
    },

    3: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P44-E3',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page44/E1/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page44/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page44/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page44/E1/3.jpg', input: true, answer: "z" },
                { url: 'img/FriendsPlus/Page44/E1/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page44/E1/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page44/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page44/E1/7.jpg', input: true, answer: "x" },
                { url: 'img/FriendsPlus/Page44/E1/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page44/E1/9.jpg' },
            ],
        ],
    },

    4: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P44-E4',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page44/E4/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page44/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page44/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page44/E4/3.jpg', input: true, answer: "r" },
                { url: 'img/FriendsPlus/Page44/E4/4.jpg' },
                { url: 'img/FriendsPlus/Page44/E4/5.jpg', input: true, answer: "s" },
                { url: 'img/FriendsPlus/Page44/E4/6.jpg' },
                { url: 'img/FriendsPlus/Page44/E4/7.jpg', input: true, answer: "t" },
                { url: 'img/FriendsPlus/Page44/E4/8.jpg' },
                { url: 'img/FriendsPlus/Page44/E4/9.jpg', input: true, answer: "u" },
                { url: 'img/FriendsPlus/Page44/E4/10.jpg' },
                { url: 'img/FriendsPlus/Page44/E4/11.jpg', input: true, answer: "v" },
                { url: 'img/FriendsPlus/Page44/E4/12.jpg', input: true, answer: "w" },
                { url: 'img/FriendsPlus/Page44/E4/13.jpg' },
                { url: 'img/FriendsPlus/Page44/E4/14.jpg', input: true, answer: "x" },
                { url: 'img/FriendsPlus/Page44/E4/15.jpg' },
                { url: 'img/FriendsPlus/Page44/E4/16.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page44/E4/17.jpg', input: true, answer: "z" },
                { url: 'img/FriendsPlus/Page44/E4/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page44/E4/19.jpg' },
            ],
        ],
    },


}

export default json;