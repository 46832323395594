import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';




const json = {
    1: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P47-E1',
        audio: '/img/FriendsPlus/Page47/Audio/audio.e1.mp3',
        video: '',
        component: DesignUnderLine,
        totalInput: 4,
        exerciseKey: 'img/FriendsPlus/Page47/E1/Key/answerKey.png',
        titleImage: '',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page47/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page47/E1/2.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page47/E1/3.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page47/E1/4.jpg', input: 1, isCorrect: true },
                { url: 'img/FriendsPlus/Page47/E1/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page47/E1/6.jpg', input: 2, isCorrect: true },
                { url: 'img/FriendsPlus/Page47/E1/7.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page47/E1/8.jpg', input: 2 },
                { url: 'img/FriendsPlus/Page47/E1/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page47/E1/10.jpg', input: 3, isCorrect: true },
                { url: 'img/FriendsPlus/Page47/E1/11.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page47/E1/12.jpg', input: 3 },
                { url: 'img/FriendsPlus/Page47/E1/13.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page47/E1/14.jpg', input: 4 },
                { url: 'img/FriendsPlus/Page47/E1/15.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page47/E1/16.jpg', input: 4, isCorrect: true },
                { url: 'img/FriendsPlus/Page47/E1/17.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P47-E2',
        audio: '',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page47/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page47/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page47/Audio/banana.mp3' },
                { url: 'img/FriendsPlus/Page47/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page47/Audio/sandwich.mp3' },
                { url: 'img/FriendsPlus/Page47/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page47/Audio/pear.mp3' },
                { url: 'img/FriendsPlus/Page47/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page47/Audio/drink.mp3' },
            ],
        ],
    },

}

export default json;