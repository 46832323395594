import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import UI from '../../components/ExcerciseTypes/UseIt';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P8-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page8/E1/1.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P8-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page8/3.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P8-E3',
    audio: '',
    video: '',
    component: D1,
    //recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page8/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page8/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/boy.mp3' },
        { url: 'img/FriendsPlus/Page8/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/bat.mp3' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P8-E4',
    audio: '/img/FriendsPlus/Page8/Audio/audio.e4.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page8/E4/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page8/E4/title_1.jpg' },
        { url: 'img/FriendsPlus/Page8/E4/title_2.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/tieude.e4.mp3' },
        { url: 'img/FriendsPlus/Page8/E4/title_3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page8/E4/2.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page8/E4/3.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page8/E4/4.jpg', input: 3, isCorrect: true },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page8/E4/5.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page8/E4/6.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page8/E4/7.jpg', input: 3 },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },



}

export default json;