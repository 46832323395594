import React, { useState } from 'react'
import 'moment/locale/vi';
import moment from 'moment';
import { Form } from 'antd';
import classNames from 'classnames';
import { Container, Row, Button, Input, Modal, ModalBody } from 'reactstrap'
import Animate from '../../../Animate';
import VideoModal from '../../../VideoModal';
import FooterModal from '../../../FooterModal';
import AudioPlayer from '../../../AudioPlayer';
import TitleQuestion from '../../../TitleQuestion';
import Firework from '../../../ResultsAndEffects/fireworks.js';

moment.locale('vi');

const FormItem = Form.Item;

const DesignUnderLine = (props) => {
  const [state, setState] = React.useState({
    star: 0,
    data: null,
    inputs: [],
    listInput: [],
    isDoing: true,
    videoUrl: null,
    audioUrl: null,
    timeStamp: null,
    audioImgUrl: null,
    booleanArray: [],
    studentAnswer: null,
    isDisabledRetry: true,
    isDisabledSubmit: true,

  });
  const [modal, setModal] = useState(false);

  // const toggle = () => setModal(!modal);

  const videoRef = React.useRef()

  const submitButtonRef = React.useRef()

  const [formCheckAnswer] = Form.useForm();


  const inputCount = React.useRef(0)

  React.useEffect(() => {
    const clonedProps = JSON.parse(JSON.stringify(props));

    const data = clonedProps?.questionImage;

    data.titleQuestion = props.titleQuestion;

    let maxInput = 0;
    let answers = []
    data.forEach((item) => {
      item.forEach((img) => {
        if (img.isInput) {
          maxInput++
          answers.push(img.answer);
        }
      })
    })

    const audioUrl = clonedProps.audio;

    setState((prevState) => ({ ...prevState, data, audioUrl, maxInput, answers }));
  }, [props]);

  const CheckFinish = React.useCallback(() => {
    const { inputs, listInput, maxInput } = state
    const isSelects = inputs.length >= (maxInput - 1)
    const isInputs = listInput.length === maxInput

    if (isSelects && isInputs) {
      setState((prevState) => ({ ...prevState, isDisabledSubmit: false }));
    }
  }, [state])

  const onChangeText = React.useCallback((inputIndex) => (e) => {
    const text = e.target.value;
    let { listInput } = state
    listInput[inputIndex] = text
    CheckFinish()

  }, [CheckFinish, state])

  const onClickImage = React.useCallback((item, index, rowIndex) => () => {
    CheckFinish()
    const url = item.audioUrl || item.videoUrl
    if (url) {
      const timeStamp = Date.now()
      if (url?.includes('.mp4')) {
        setState((prevState) => ({ ...prevState, videoUrl: url }))
        return videoRef.current?.toggleModal('isVisibled')
      }
      if (state.audioUrl) {
        document.getElementById('myAudio')?.pause()
      }
      setState((prevState) => ({ ...prevState, audioImgUrl: url, timeStamp }))
    }
    if (!item.input || !state.isDoing || item.isInput) return;

    setState((prevState) => {
      const inputs = JSON.parse(JSON.stringify(prevState.inputs));

      const isTotal = (inputs.length + 1) >= props.totalInput;

      // if (isTotal) {
      //   setState((prevState) => ({ ...prevState, isDisabledSubmit: false }))
      // }
      const inputIndex = inputs.findIndex((x) => x.input === item.input);

      if (inputIndex > -1) {
        inputs[inputIndex].select = index;
        inputs[inputIndex].rowIndex = rowIndex;
      } else {
        inputs.push({ input: item.input, select: index, rowIndex });
      }

      return ({ ...prevState, inputs });
    })
  }, [CheckFinish, props.totalInput, state.audioUrl, state.isDoing]);

  const renderImage = React.useCallback((images, rowIndex) => {
    return images.map((item, index) => {
      let imageSrc = item.url;
      let inputIndex = -1;
      let isSelecting = false;
      let inputCurrent = 0
      let color = 'black';
      let styleInput = null;
      if (item.input) {
        inputIndex = state.inputs.findIndex((x) => x.input === item.input);
        if (inputIndex > -1) {
          isSelecting = state.inputs[inputIndex]?.select === index && state.inputs[inputIndex]?.rowIndex === rowIndex;
          if (isSelecting) {
            if (state.isDoing) {
              imageSrc = imageSrc.replace('.jpg', 'select.jpg');
            } else {
              imageSrc = imageSrc.replace('.jpg', 'answer.jpg');
            }
          }
        }
      }
      if (item?.isInput) {
        styleInput = {
          color,
          height: '100%',
          width: '100%',
          boxShadow: 'none',
          borderWidth: 0,
          borderRadius: 0,
          marginBottom: 12,
          borderColor: color,
          fontWeight: '500',
          backgroundColor: 'transparent',
          fontSize: props.fontSize ?? 40,
          textAlign: 'center',

        }
        inputCurrent = inputCount.current;
        inputCount.current++
        if (inputCurrent === state.maxInput - 1) {
          inputCount.current = 0
        }
        if (!state.isDoing) {
          const isCorrect = state.booleanArray?.[inputCurrent]
          color = isCorrect ? '#2DCE89' : '#F13536'
        }
      }

      return (
        <div key={index} style={{ display: 'grid', position: 'relative' }}>
          <img
            alt="..."
            src={imageSrc}
            style={{ cursor: item.input ? 'pointer' : undefined, width: '100%' }}
            onClick={onClickImage(item, index, rowIndex)}
          />
          {item.isInput && (
            <FormItem
              style={{ display: 'inline-block', position: 'absolute', right: 0, left: 0, padding: 0 }}
              name={inputCurrent}
            >
              <Input
                allowClear
                onChange={onChangeText(inputCurrent)}
                maxLength={1}
                disabled={!state.isDoing}
                style={styleInput}
              ></Input>
            </FormItem>
          )}
          {!state.isDoing && item.isInput && (
            <i
              className={classNames({
                "fas fa-check": state.booleanArray?.[inputCurrent],
                "fas fa-times": !state.booleanArray?.[inputCurrent]
              })}
              style={{
                color,
                top: -1,
                zIndex: 1,
                right: -10,
                fontSize: 20,
                position: 'absolute',
              }}
            />
          )}
          {(!state.isDoing && item.input && isSelecting) && (
            <i
              className={classNames({
                "fas fa-check": item?.isCorrect,
                "fas fa-times": !item?.isCorrect
              })}
              style={{
                color: item?.isCorrect ? 'green' : 'red',
                top: -1,
                right: -20,
                fontSize: 20,
                zIndex: 1000,
                position: 'absolute',
              }}
            />
          )}
        </div>
      );
    });
  }, [onClickImage, props.fontSize, state.booleanArray, state.inputs, state.isDoing, state.maxInput]);

  const renderContent = React.useMemo(() => {
    return state.data?.map((rowItem, rowIndex) => {
      return (
        <div key={rowIndex} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {renderImage(rowItem, rowIndex)}
        </div>
      )
    })
  }, [renderImage, state.data]);

  const onSubmit = React.useCallback(() => {
    submitButtonRef.current?.click()

    setState((prevState) => ({ ...prevState, isDoing: false, isDisabledRetry: false, isDisabledSubmit: true, }))
  }, []);

  const onFinish = React.useCallback((values) => {
    const { data, inputs } = state
    let studentAnswer = []
    let booleanArray = [];
    //check input
    for (let i = 0; i < state.maxInput; i++) {
      let isCorrect = false;
      const answersInArray = state.answers[i];
      const isManyAnswers = answersInArray.includes('/');
      if (isManyAnswers && values[i]) {

        const answers = answersInArray.split('/');

        for (let j = 0; j < answers.length; j++) {
          isCorrect = answers[j].trim().toLowerCase() === values[i].trim().toLowerCase();
          if (isCorrect) break;
        }
      } else {
        if (!answersInArray) {
          isCorrect = true;
        }
        else {
          if (values[i]) {
            isCorrect = values[i].trim().toLowerCase() === state.answers[i].trim().toLowerCase();
          }
        }
      }
      studentAnswer.push(values[i] ?? null);
      booleanArray.push(isCorrect);
    }
    //check select
    for (let i = 0; i < state.maxInput; i++) {
      if (inputs[i]) {
        const { select, rowIndex } = inputs[i];
        const item_select = data[rowIndex][select]
        booleanArray.push(item_select.isCorrect ?? false);
        studentAnswer.push(item_select.url ?? null);
      }
      else {
        booleanArray.push(false);
        studentAnswer.push(null);
      }

    }

    let correctAnswer = 0;
    booleanArray.forEach((item, index) => {
      if (item) {
        correctAnswer++
      }
    })
    const percent = parseInt(correctAnswer / booleanArray.length * 100)
    // const resultString = `${correctAnswer}/${booleanArray.length} (${percent}%)`
    const result_String = `${correctAnswer}/${booleanArray.length}`
    const star = percent / 20;

    const params = {
      score: percent,
      unit: props.unit,
      results: result_String,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    }
    console.log(params);

    props.postAnswerToApi(params)

    onModal();

    setState((prevState) => ({ ...prevState, booleanArray, star }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, state])

  const onModal = React.useCallback(() => {
    setModal(true)
    setTimeout(() => {
      offModal();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const offModal = React.useCallback(() => {
    setModal(false)
  }, []);
  const onRetry = React.useCallback(() => {
    formCheckAnswer.resetFields();

    setState((prevState) => ({
      ...prevState,
      inputs: [],
      listInput: [],
      isDoing: true,
      isDisabledRetry: true,
      isDisabledSubmit: true,
    }))
  }, [formCheckAnswer]);

  if (!state.data) return null;
  console.log(state);
  return (
    <Container className='fluid'>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TitleQuestion titleQuestion={state.data?.titleQuestion} />
      </Row>
      <Row style={{ minWidth: 985, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Form form={formCheckAnswer} autoComplete="off" onFinish={onFinish}>
          {renderContent}
          <Button innerRef={submitButtonRef} style={{ display: 'none' }} htmlType="submit" >text</Button>

        </Form>

      </Row>

      {state.audioImgUrl && (
        <AudioPlayer
          id='audio'
          timeStamp={state.timeStamp}
          isPause={state.isPause}
          audioUrl={state.audioImgUrl}
          isVisibled={props.isVisibled}
        />
      )}
      {state.videoUrl && (
        <VideoModal ref={videoRef} videoUrl={state.videoUrl} />
      )}
      <Modal centered isOpen={modal} style={{ maxWidth: 640, }}>
        <ModalBody>
          {/* {renderContent} */}
          {(state.star >= 1) && (
            <Firework />
          )}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 240 }}>
            <Animate
              resultString={state.star}
            />
          </div>
        </ModalBody>
      </Modal>
      <FooterModal
        onRetry={onRetry}
        onSubmit={onSubmit}
        audioUrl={state.audioUrl}
        result={state.booleanArray?.concat(state.booleanArray)}
        exerciseKey={props.exerciseKey}
        isDisabledSubmit={state.isDisabledSubmit}
        isDisabledRetry={state.isDisabledRetry}
      />
    </Container>
  )
}
export default DesignUnderLine