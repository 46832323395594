import L1 from '../../components/ExcerciseTypes/Design/Listen';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {


  1: { // Exercise num
    unit: 'Culture 3',
    id: 'WB1-C-P54-E1',
    audio: '',
    video: '',
    component: D1,

    fontSize: 30,
    exerciseKey: '',
    inputHeight: '',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page54/E1/1.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Culture 3',
    id: 'WB1-C-P54-E2',
    audio: '',
    video: '',
    component: L1,
    titleImage: '',
    question: [

      {
        imgUrl: [
          'img/FriendsPlus/Page54/E2/3.jpg',
          'img/FriendsPlus/Page54/E2/5.jpg',
          'img/FriendsPlus/Page54/E2/6.jpg',
          'img/FriendsPlus/Page54/E2/7.jpg',
          'img/FriendsPlus/Page54/E2/8.jpg',
        ],
        audioUrl: [
          "",
          "img/FriendsPlus/Page54/E2/Audio/1.mp3",
          "img/FriendsPlus/Page54/E2/Audio/2.mp3",
          "img/FriendsPlus/Page54/E2/Audio/3.mp3",
          "img/FriendsPlus/Page54/E2/Audio/4.mp3",

        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}
export default json;