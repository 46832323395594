import UI from '../../components/ExcerciseTypes/UseIt';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P7-E4',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page7/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P7-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: true,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page7/E2/1.jpg' },
      ],
    ]
  },

  3: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P7-E4',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page7/E3/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page7/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page7/E3/2.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E3/3.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page7/E3/4.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page7/E3/5.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page7/E3/6.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page7/E3/7.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page7/E3/8.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page7/E3/9.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page7/E3/10.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page7/E3/11.jpg', input: 10 },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;