import UI from '../../components/ExcerciseTypes/UseIt';
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {

  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P36-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page36/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P36-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page36/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/arms.mp3' },
        { url: 'img/FriendsPlus/Page36/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/nose.mp3' },
        { url: 'img/FriendsPlus/Page36/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/face.mp3' },
        { url: 'img/FriendsPlus/Page36/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/legs.mp3' },
        { url: 'img/FriendsPlus/Page36/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/fingers.mp3' },
        { url: 'img/FriendsPlus/Page36/E2/9.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/hands.mp3' },
      ],


    ],
  },


}

export default json;