// import IT from '../../components/ExcerciseTypes/IMAGETEXT/IT1';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {
  1: { // Exercise num
    unit: 'Culture 1',
    id: 'WB1-C-P52-E1',
    //audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page52/E1/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '0% 50%',
    titleImage: 'img/FriendsPlus/Page52/E1/1.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page52/E1/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page52/E1/5.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page52/E1/8.jpg', isMatching: true, id: 3 },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/4.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page52/E1/7.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page52/E1/10.jpg', isMatching: true, id: 6 },
      ],
    ],
    answers: ['1-6', '2-4', '3-5'],
  },
  2: { // Exercise num
    unit: 'Culture 1',
    id: 'WB1-C-P52-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page52/E2/1.jpg' },
      ],
    ]
  },

}

export default json;