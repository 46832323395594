
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';



const json = {


  1: { // Exercise num
    unit: 'Culture 5',
    id: 'WB1-C-P56-E1',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    fontSize: 40,
    inputHeight: 42,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page56/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page56/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page56/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/3.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page56/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/5.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page56/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/7.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page56/E1/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page56/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page56/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/11.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page56/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/13.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page56/E1/14.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page56/E1/15.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page56/E1/16.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page56/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/18.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page56/E1/19.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page56/E1/20.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Culture 5',
    id: 'WB1-C-P56-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: true,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page56/E2/3.jpg' },
      ],
    ]
  },
}
export default json;