import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {

  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB1-U4-P35-E1',
    audio: 'img/FriendsPlus/Page35/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    //recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page35/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page35/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page35/Audio/anh1.e1.mp3' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page35/Audio/anh2.e1.mp3' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page35/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page35/Audio/anh3.e1.mp3' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page35/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page35/Audio/anh4.e1.mp3' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page35/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page35/Audio/anh5.e1.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB1-U4-P35-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page35/Audio/What are they.mp3' },
        { url: 'img/FriendsPlus/Page35/E2/4.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/They're bears..mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/6.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/What are they.mp3" },
        { url: 'img/FriendsPlus/Page35/E2/7.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/They're hippos..mp3" },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/9.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/What are they.mp3" },
        { url: 'img/FriendsPlus/Page35/E2/10.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/They're tigers..mp3" },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page35/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/12.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/What are they.mp3" },
        { url: 'img/FriendsPlus/Page35/E2/13.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/They're birds..mp3" },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page35/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/15.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/What are they.mp3" },
        { url: 'img/FriendsPlus/Page35/E2/16.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/They're crocodiles..mp3" },
      ],

    ],
  },


}

export default json;