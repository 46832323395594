
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB1-U1-P15-E1',
    audio: 'img/FriendsPlus/Page15/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    //recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page15/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page15/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page15/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page15/E1/4.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB1-U1-P15-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page15/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page15/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/What color is it..mp3' },
        { url: 'img/FriendsPlus/Page15/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/5.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/It's black..mp3" },
        { url: 'img/FriendsPlus/Page15/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page15/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/What color is it..mp3' },
        { url: 'img/FriendsPlus/Page15/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/10.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/It's green..mp3" },
        { url: 'img/FriendsPlus/Page15/E2/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page15/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/13.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/What color is it..mp3' },
        { url: 'img/FriendsPlus/Page15/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/15.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/It's red..mp3" },
        { url: 'img/FriendsPlus/Page15/E2/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page15/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/18.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/What color is it..mp3' },
        { url: 'img/FriendsPlus/Page15/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/20.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/It's blue..mp3" },
        { url: 'img/FriendsPlus/Page15/E2/21.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page15/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/23.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/What color is it..mp3' },
        { url: 'img/FriendsPlus/Page15/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/25.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/It's yellow..mp3" },
        { url: 'img/FriendsPlus/Page15/E2/26.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page15/E2/27.jpg' },
      ],


    ],
  },
}

export default json;